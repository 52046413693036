@media only screen and (max-width: 768px){
    .ListTimeItem p {
        font-size: 22px;
    }
}
@media only screen and (min-width: 768px){
    .ListTimeItem p {
        font-size: 25px;
    }
}

.ListTimeItem .location {
    color: var(--secondary);
    font-weight: bold;
}

.ListTimeItem .time {
    color: var(--primary);
    font-weight: normal;
    margin-left: 2rem;
}
