:root {
	--bg: #FEF7DC;
	--primary: #A19882;
	--secondary: #887E66;
}

[data-theme='dark'] {
	--bg: #222831;
	--primary: #CECECE;
	--secondary: #FFD369;
}

html.theme-changing,
html.theme-changing *,
html.theme-changing *:before,
html.theme-changing *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}


body {
	font-family: 'Helvetica', 'Arial', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	background-color: var(--bg);
}

@media only screen and (max-width: 768px){
	h1 {
		font-size: 40px;
	}
	
	p {
		font-size: 25px;
	}
}

@media only screen and (min-width: 768px){
	h1 {
		font-size: 50px;
	}
	
	p {
		font-size: 30px;
	}
}

hr {
	background-color: var(--primary);
	height: 2px;
	border: 0;
}

h1 {
    color: var(--secondary);
    margin: 0;
}

p {
    color: var(--primary);
    margin: 0;
}
