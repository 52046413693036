.main-time {
    font-weight: bold;
    color: var(--secondary);
}

/* Phone screens */
@media only screen and (max-width: 768px) {
    .content {
        margin-top: 5rem;
        position: absolute;
        margin-left: 1rem;
        margin-right: 1rem;
        left: 1rem;
        top: 1rem;
        bottom: 1rem;
    }
    .svg-button {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 2rem;
    }
}

@media only screen and (orientation:landscape) {
    .svg-button {
        position: fixed;
        top: 0;
        left: auto;
        right: 0;
        padding: 2rem;
    }
    .content {
        position: absolute;
        margin-top: 1rem;
        left: 1rem;
        top: 1rem;
    }
}

@media only screen and (min-width: 768px) {
    .content {
        position: absolute;
        left: 50%;
        top: 25%;
        width: 40rem;
        margin-top: 5rem;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .svg-button {
        position: fixed;
        top: 0;
        left: 0;
        margin: 2rem;
    }
}

.svg-button {
    fill: var(--primary);
}

.svg-button:hover {
    fill: var(--secondary);
}

.owner {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
}

.owner a:hover {
    color: var(--secondary);
}

.owner a {
    color: var(--primary);
    text-decoration: underline;
}